import { render, staticRenderFns } from "./MonitorsSettingsGeneral.vue?vue&type=template&id=2fc5aec9&scoped=true"
import script from "./MonitorsSettingsGeneral.vue?vue&type=script&lang=js"
export * from "./MonitorsSettingsGeneral.vue?vue&type=script&lang=js"
import style0 from "./MonitorsSettingsGeneral.vue?vue&type=style&index=0&id=2fc5aec9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc5aec9",
  null
  
)

export default component.exports